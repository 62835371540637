<template>
    <div class="wrapper">
        <svgicon name="triangle" class="arrow" />

        <div
            class="payment-plan-summary"
            :class="{
                completed: plan_completed,
                loading,
            }"
            @click="handleClick"
        >
            <div class="header">
                <span class="name">{{ estimate_ref }}</span>

                <span v-if="loading" class="tag">
                    -
                </span>
                <span v-else-if="tag_label" class="tag">
                    {{ tag_label }}
                </span>
            </div>

            <loader v-if="loading" message="" />
            <template v-else>
                <template v-if="plan_completed">
                    <div class="info-container">
                        <span class="completed">
                            <strong>{{ estimate_amount | currency }}</strong>
                            over <strong>{{ invoices_count }}</strong> invoices
                        </span>
                    </div>
                </template>

                <template v-else-if="plan_pending || plan_planned">
                    <div class="info-container">
                        <div class="totals">
                            <div v-if="overdue_amount > 0" style="flex: 1;">
                                <el-tooltip
                                    :content="overdue_amount_string"
                                    placement="top"
                                >
                                    <div class="overdue" />
                                </el-tooltip>
                            </div>
                            <template v-if="show_paid_amount">
                                <span class="total paid" data-label="paid">
                                    {{ paid_amount | currency }}
                                </span>
                                <span>/</span>
                            </template>
                            <template v-if="show_invoiced_amount">
                                <span
                                    class="total invoiced"
                                    data-label="invoiced"
                                >
                                    {{ invoiced_amount | currency }}
                                </span>
                            </template>
                            <span
                                v-if="
                                    show_invoiced_amount && show_planned_amount
                                "
                            >
                                /
                            </span>
                            <template v-if="show_planned_amount">
                                <span
                                    class="total planned"
                                    data-label="planned"
                                >
                                    {{ planned_amount | currency }}
                                </span>
                            </template>
                            <span
                                v-if="
                                    show_planned_amount && show_estimate_amount
                                "
                            >
                                /
                            </span>
                            <template v-if="show_estimate_amount">
                                <span
                                    class="total pending"
                                    data-label="pending"
                                >
                                    {{ estimate_amount | currency }}
                                </span>
                            </template>

                            <span v-if="plan_invoiced" class="count">
                                <strong>{{ invoices_paid }}</strong> /
                                <strong>{{ invoices_count }}</strong>
                                paid
                            </span>
                            <span v-else-if="invoices_count > 0" class="count">
                                <strong>{{ invoices_sent }}</strong> /
                                <strong>{{ invoices_count }}</strong>
                                sent
                            </span>
                        </div>

                        <div class="progress-bars">
                            <el-progress
                                :percentage="planned_percent"
                                :show-text="false"
                                color="#0f263b"
                            />
                            <el-progress
                                :percentage="invoiced_percent"
                                :show-text="false"
                                color="#409eff"
                                class="no-background"
                            />
                            <el-progress
                                :percentage="paid_percent"
                                :show-text="false"
                                color="#90d050"
                                class="no-background"
                            />
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import PaymentPlanMixin from '@/mixins/paymentplan.mixin';

import Loader from '@/components/generic/Loader';

import {toleranceRound} from '@/utils';

export default {
    name: 'payment-plan-summary-block',
    components: {Loader},
    mixins: [PaymentPlanMixin],
    props: {
        estimate: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            payment_plan_invoices: [],
        };
    },
    computed: {
        estimate_ref() {
            return this.estimate.ref;
        },

        estimate_amount() {
            return this.estimate.final_cost;
        },
        planned_amount() {
            return this.getEstimatePaymentPlanTotal(this.estimate.id);
        },
        invoiced_amount() {
            return this.getEstimatePaymentPlanTotal(this.estimate.id, true);
        },
        paid_amount() {
            return this.getEstimatePaymentPlanPaid(this.estimate.id);
        },
        overdue_amount() {
            return this.getEstimatePaymentPlanOverdue(this.estimate.id);
        },
        overdue_amount_string() {
            return (
                this.$options.filters.currency(this.overdue_amount) + ' overdue'
            );
        },

        show_estimate_amount() {
            return this.plan_pending && !this.plan_planned;
        },
        show_planned_amount() {
            return this.planned_amount > 0 && !this.plan_invoiced;
        },
        show_invoiced_amount() {
            return this.invoiced_amount > 0 && !this.plan_completed;
        },
        show_paid_amount() {
            return this.paid_amount > 0 && !this.plan_completed;
        },
        show_overdue_amount() {
            return this.overdue_amount > 0 && !this.plan_completed;
        },

        invoices_count() {
            return this.getPaymentPlanInvoiceCount(this.estimate.id);
        },
        invoices_sent() {
            return this.getPaymentPlanInvoiceCount(this.estimate.id, true);
        },
        invoices_paid() {
            return this.getPaymentPlanInvoicePaidCount(this.estimate.id);
        },

        needs_planning() {
            return this.estimate_amount > this.planned_amount;
        },

        all_invoices_sent() {
            return this.isEstimatePaymentPlanSent(this.estimate_id);
        },
        all_invoices_paid() {
            return this.isEstimatePaymentPlanPaid(this.estimate_id);
        },

        planned_percent() {
            if (this.estimate_amount > 0) {
                return Math.min(
                    (this.planned_amount / this.estimate_amount) * 100,
                    100
                );
            } else {
                // if any invoices have been created, consider the the estimate planned
                return this.payment_plan_invoices ? 100 : 0;
            }
        },
        invoiced_percent() {
            if (this.estimate_amount > 0) {
                return Math.min(
                    (this.invoiced_amount / this.estimate_amount) * 100,
                    100
                );
            } else {
                return this.all_invoices_sent ? 100 : 0;
            }
        },
        paid_percent() {
            if (this.estimate_amount > 0) {
                return Math.min(
                    (this.paid_amount / this.estimate_amount) * 100,
                    100
                );
            } else {
                return this.all_invoices_paid ? 100 : 0;
            }
        },

        plan_pending() {
            return this.needs_planning || !this.all_invoices_sent;
        },
        plan_planned() {
            return toleranceRound(this.planned_percent) >= 100;
        },
        plan_invoiced() {
            return toleranceRound(this.invoiced_percent) >= 100;
        },
        plan_completed() {
            return toleranceRound(this.paid_percent) >= 100;
        },

        tag_label() {
            if (this.plan_completed) return 'Completed';
            else if (this.plan_invoiced) return 'Invoiced';
            else if (this.plan_planned) return 'Planned';
            else if (this.payment_plan_invoices.length) return 'In progress';
            else return 'Pending';
        },
    },
    mounted() {
        this.$fire
            .collection('payment_plan_invoices')
            .where(
                'project',
                '==',
                this.$fire
                    .collection('projects')
                    .doc(this.$route.params.project_id.toString())
            )
            .where(
                'estimate',
                '==',
                this.$fire.collection('estimates').doc(this.estimate.ref)
            )
            .get()
            .then((snapshot) => {
                snapshot.forEach((ppl) => {
                    const data = ppl.data();
                    this.payment_plan_invoices.push(data);
                });

                this.loading = false;
            });
    },
    methods: {
        handleClick() {
            this.$router.push({
                name: 'project_detail_paymentplan',
                params: {
                    project_id: this.$route.params.project_id,
                    estimate_id: this.estimate.id,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    display: flex;
    flex-direction: row;
    flex: 1.3;

    .arrow {
        width: 8px;
        margin: 10px 20px;
    }

    .payment-plan-summary {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 20px;
        border-radius: 5px;
        border: 1px solid $border-grey;
        background-color: white;
        box-sizing: border-box;
        user-select: none;
        cursor: pointer;

        &.loading,
        &.completed {
            opacity: 0.5;
            background-color: none;
        }

        &:hover {
            box-shadow: 0 0 0 1px $blue;
            border: 1px solid $blue;

            .info-container {
                span.hidden {
                    display: flex;
                }
            }
        }

        @media screen and (max-width: 992px) {
            width: 100%;
            margin-right: 0;
        }

        ::v-deep .el-progress-bar__outer {
            box-shadow: 0 0 0 4px white;
        }

        .header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            min-width: 90px;

            span.name {
                font-size: 15px;
                display: block;
            }
        }

        .loader {
            max-width: 200px;
            justify-content: flex-end;
        }

        .info-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 15px;
            border-left: 1px solid $border-grey;
            padding-left: 15px;
            min-width: 200px;
            flex: 1;

            .completed {
                font-size: 12px;
                margin: 0 5px;
                text-align: right;
            }

            .overdue {
                background: $red;
                width: 10px;
                height: 10px;
                border-radius: 50%;
            }

            .totals {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                font-size: 12px;

                .total {
                    font-weight: bold;
                    position: relative;
                    margin: 0 5px;
                    text-align: right;
                    margin-top: 6px;

                    &:first-child {
                        margin-left: 0;
                    }

                    &::before {
                        content: attr(data-label);
                        position: absolute;
                        top: -9px;
                        font-size: 6px;
                        width: 100%;
                        text-align: left;
                        font-weight: bolder;
                    }

                    &.pending {
                        color: grey;
                    }

                    &.planned {
                        color: $black;
                    }

                    &.invoiced {
                        color: $blue;
                    }

                    &.paid {
                        color: $green;
                    }

                    &.overdue {
                        color: $red;
                    }
                }

                .count {
                    flex: 1;
                    text-align: right;
                }
            }

            .progress-bars {
                height: 6px;
                margin-top: 8px;
                margin-bottom: 0px;
                position: relative;

                ::v-deep .el-progress {
                    position: absolute;
                    width: 100%;
                    margin: 0;

                    &.no-background {
                        .el-progress-bar__outer {
                            background-color: transparent !important;
                        }

                        .el-progress-bar__inner {
                            box-shadow: 2px 0 0 0 white;
                        }
                    }
                }
            }
        }

        .tag {
            font-size: 10px !important;
            text-transform: capitalize;
            padding: 5px 0 0 0;
        }
    }
}
</style>
